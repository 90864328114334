.page-title {
  display: flex;
}

.header .nav-item {
  padding-right: 0 !important;
}

.header .header-toggler {
  margin-left: 0.25rem !important;
}

.header-profile {
  align-items: center;
  display: flex;
}

.header-region {
  font-size: 0.7rem;
  line-height: 0.7rem;
}

.username-input-wrapper {
  font-weight: 400;
  line-height: 2.5rem;
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 1.5rem;
}

.username-input-wrapper button {
  padding-left: 0.5rem !important;
  margin-left: 1rem;
}

.user-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 200px;
}

.username-input-wrapper input {
  max-width: 250px;
}

.form-control.filter-select {
  max-width: 20rem;
}

.page-title {
  flex-grow: 1;
}

.header .container {
  padding-right: 0.5rem;
}

.header-wrapper {
  flex-grow: 1;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.header-title {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

.header-fetch-wrapper {
  display: flex;
  align-items: center;
}

.header-fetch-wrapper button {
  height: 30px;
}

.header-fetch-wrapper input {
  max-width: 130px;
}

.top-row .card .text-right {
  visibility: hidden;
}

.flex-grow {
  flex-grow: 1;
}

.pointer {
  cursor:pointer;
}

.cursor-default {
  cursor: default !important;
}

.card-table abbr:not(.abbr) {
  text-decoration: none !important;
}


.links-name {
  width: 10rem;
}

.text-bold {
  font-weight: 400 !important;
}

.inline-block {
  display: inline-block !important;
}

.flex-center {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/** Background */
.dark body,
.dark .header input,
.dark .header .btn-secondary,
.dark .tooltip-container {
  background-color: #333;
  color: #fefefe;
}

/* Z-index 1 items */
.dark .header,
.dark .btn-secondary,
.dark input,
.dark .footer,
.dark .card {
  background-color: #000;
  color: #fefefe;
}

.dark .header {
  border-bottom-color: rgb(60,60,60);
}

.dark .alert.alert-primary {
  background-color: #666;
  border-color: #666;
  color: #fefefe;
}

.dark a {
  color: #fff !important;
  font-weight: 600;
}

/**
 tooltip
*/

.tooltip-container {
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  max-width: 250px;
  text-align: left;
  white-space: normal;
}

/**
 Compare
*/

.compare-category-graphic.my-4 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}

.hide {
  display: none !important;
}

.compare-user-remove {
  position: absolute;
  top: 10px;
  right: 10px;
}

.col-sm-3 h2 {
  font-size: 1.1rem;
}

.col-sm-3 .display-4 {
  font-size: 1.5rem;
}

.compare .card {
  height: calc(100% - 1.5rem);
}